@import "../../variables.modules";


.showButton {
    border-color: $green;
    color: $white;
    background-color:$purple;
    padding: 8px 16px;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: $white;
      color: $purple;
      text-decoration: none;
    }
  }