@import "../variables.modules";

nav {
  transition: $transition;
  margin: 20px; /* add 10 pixels of margin on all sides */
  border: 2px 
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.link:hover {
  color: unset;
}
.burgerIcon {
  font-size: 2rem;
  cursor: pointer;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  justify-content: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.fadeIn {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}


@media (min-width: 769px) {
  .burgerIcon {
    display: none;
  }
  
}
