@import "../variables.modules";


.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  h1 {
    color: red;
  }

  p {
    margin-top: 1rem;
  }

  a {
    margin-top: 1rem;
  }
}

.button {
  margin: 0 0.5rem;
}

